import React from "react";
import TeaOnPaperImage from "../assets/images/002.jpg";
import RatesImage from "../assets/images/rates.png";

const AdRate = () => {
  return (
    <div>
      <section id="adrates" className="why-us-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-column order-last col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  <h2>Rates for Advertisers</h2>
                </div>
                <img src={RatesImage} alt="" />
              </div>
            </div>
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="image">
                  <a
                    href="images/resource/002.jpg"
                    className="lightbox-image"
                    data-fancybox="images"
                  >
                    <img src={TeaOnPaperImage} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdRate;
