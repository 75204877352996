import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const ContactForm = () => {
    const [ishuman, setishuman] = useState(false);
    const [toggle, settoggle] = useState(false);
    const [formState, setFormState] = useState({
      fullname: "",
      email: "",
      message: "",
    });
    const sitekey = process.env.REACT_APP_RECAPTCHA_KEY;
    const atrefRestApiHeaderKey = process.env.REACT_APP_ATREF_RESTAPIS_ACCESS_HEADER_KEY;
    const atrefRestApiHeaderValue = process.env.REACT_APP_ATREF_RESTAPIS_ACCESS_HEADER_VALUE;
    const recaptchaVerifyUrl = process.env.REACT_APP_RECAPTCHA_VERIFY_URL;
    const sendEmailUrl = process.env.REACT_APP_SENDEMAIL_URL;
    const emailDestinationOverride = process.env.REACT_APP_DESTINATION_EMAIL_OVERRIDE;

    // Handler function to update form state
    const handleFormChange = (event) => {
      setFormState({
        ...formState,
        [event.target.name]: event.target.value,
      });
    };

    // This method is invoked when the recaptcha is clicked
    // Based on the result, the submit button will be enabled
    // when clicked but the value of ishuman is sent to the
    // cloud function backend to determine if email needs to go
    // out or stored on repository flagged as bot email
    const handleRecaptchaChange = (value) => {
        // enable sumbit button toggle on whether it is human or not
        settoggle(true);
        let url = recaptchaVerifyUrl;
        url = url + "?token=" + value;
        axios
          .get(url, {
            headers: {
              [atrefRestApiHeaderKey]: atrefRestApiHeaderValue,
            },
          })
          .then((response) => {
            console.log(response.data.success);
            if (response.data.success) {
              // flag it is from human not bot
              setishuman(true);
            } else {
              console.log(response.data);
              // flag it is from a bot
              setishuman(false);
              window.grecaptcha.reset(); // reset recaptcha
            }
          })
          .catch((error) => {
            console.log(error.data);
            // flag it is from a bot
            setishuman(false);
            window.grecaptcha.reset(); // reset recaptcha
          });
    }

    // This method is invoked when submit button is clicked.
    // This method checks standard validation of the form for
    // completeness. Envelopes the information in JSON and sends
    // it to the cloud function to send out the email.
    const handleSendEmail = () => {
        // eslint-disable-next-line no-useless-escape
        var validEmailEx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (formState.fullname === null || formState.fullname.length < 3) {
            alert('Please input a valid full name');
            return false;
        }
        if (formState.email === null || !validEmailEx.test(formState.email)) {
            alert('Please input a valid email');
            return false;
        }
        if (formState.message === null || formState.message.length < 10) {
            alert('Please input a valid message');
            return false;
        }

        var url = sendEmailUrl;
        axios
          .post(
            url,
            {
              fullname: formState.fullname,
              email: formState.email,
              message: formState.message,
              ishuman: ishuman,
              ...(emailDestinationOverride && { emailDstOverride: emailDestinationOverride })
            },
            {
              headers: {
                [atrefRestApiHeaderKey]: atrefRestApiHeaderValue,
              },
            }
          )
          .then((response) => {
            setFormState({
              fullname: "",
              email: "",
              message: ""
            });
            alert("Email successfully sent");
          })
          .catch((error) => {
            console.log(error);
            // reset recaptcha and set toggle to off
            window.grecaptcha.reset();
            settoggle(false);
          });
        // reset recaptcha and set toggle to off
        window.grecaptcha.reset();
        settoggle(false);
    }
    return (
        <div>
            <section id="contact" className="contact-form-section">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="sec-title text-center">
                            <h2>Contact Us</h2>
                        </div>
                        <div className="contact-form-two">
                            <form id="contact-form">

                                <div className="row clearfix">
                                    <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                        <input id="fullname" type="text" name="fullname" placeholder="Your Full Name" required="" value={formState.fullname} onChange={handleFormChange}/>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12 form-group">
                                        <input id="email" type="email" name="email" placeholder="Your Email" required="" value={formState.email} onChange={handleFormChange}/>
                                    </div>

                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                                        <textarea id="message" name="message" placeholder="Your Message" value={formState.message} onChange={handleFormChange}></textarea>
                                    </div>

                                    <div className="col-md-12 col-sm-12 col-xs-12 form-group text-center">
                                        <div>
                                            <ReCAPTCHA
                                                className="centered"
                                                sitekey={sitekey}
                                                onChange={handleRecaptchaChange}
                                            />
                                        </div>
                                        <button
                                            disabled={!toggle}
                                            type="button"
                                            className={toggle ? "theme-btn btn-style-three" : "theme-btn btn-style-disabled"}
                                            name="Submit"
                                            onClick={handleSendEmail}
                                        >
                                            Send Message
                                        </button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default ContactForm;