import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";

const LowerHeaderHome = () => {

  return (
    <header id="header-id" className="main-header">

      {/* Header Lower */}
      <div className="header-lower">
        <div className="auto-container">
          <div className="inner-container clearfix">
            <div className="logo-box">
              <div className="logo">
                <a href="/">
                  <img src={Logo} alt="" title="" />
                </a>
              </div>
            </div>
            <div className="nav-outer">
              <div className="main-box clearfix">
                {/* Main Menu */}
                <nav className="main-menu navbar-expand-md navbar-light">
                  <div className="navbar-header">
                    {/* Toggle Button */}
                    <button
                      className="navbar-toggler collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="la la-bars"></span>
                    </button>
                  </div>
                  <div
                    className="collapse navbar-collapse clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className="current">
                        <a href="#home">Home</a>
                      </li>
                      <li>
                        <a href="#about">About</a>
                      </li>
                      <li>
                        <a href="#services">Services</a>
                      </li>
                      <li>
                        <a href="#factsheets">FAQs</a>
                      </li>
                      <li>
                        <a href="#adrates">Rates</a>
                      </li>
                      <li>
                        <a href="#exclusiveadvertisers">Advertisers</a>
                      </li>
                      <li>
                        <Link to={{ pathname: "/important-links", hash: "#" }}>Important Links</Link>
                      </li>
                      <li>
                        <a href="#contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </nav>
                {/* Main Menu End */}
                <div className="outer-box">
                  {/* Search Box */}
                  <div className="search-box-outer">
                    <div className="dropdown">
                      <button
                        className="search-box-btn dropdown-toggle"
                        type="button"
                        id="dropdownMenu3"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="la la-search"></span>
                      </button>
                      <ul
                        className="dropdown-menu pull-right search-panel"
                        aria-labelledby="dropdownMenu3"
                      >
                        <li className="panel-outer">
                          {/* used to be a search form here */}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*End Header Lower */}
      
      {/* Sticky Header  */}
      <div className="sticky-header">
        <div className="auto-container clearfix">
          {/*Logo */}
          <div className="logo pull-left">
            <a href="index.html" title="">
              <img src="images/logo-small.png" alt="" title="" />
            </a>
          </div>
          {/*Right Col */}
          <div className="pull-right">
            {/* Main Menu  */}
            <nav className="main-menu navbar-expand-md navbar-light">
              <div className="navbar-collapse collapse clearfix">
                <ul className="navigation clearfix">
                  <li className="current">
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#services">Services</a>
                  </li>
                  <li>
                    <a href="#factsheets">FAQs</a>
                  </li>
                  <li>
                    <a href="#adrates">Rates</a>
                  </li>
                  <li>
                    <a href="#exclusiveadvertisers">Advertisers</a>
                  </li>
                  <li>
                    <Link to={{ pathname: "/important-links", hash: "#" }}>Important Links</Link>
                  </li>
                  <li>
                    <a href="#contact">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>
            {/* Main Menu End */}
          </div>
        </div>
      </div>
      {/*End Sticky Header */}

    </header>
  );
};

export default LowerHeaderHome;
