import React from "react";
import { Link } from "react-router-dom";
import Client1Image from "../assets/images/Client1.png";
import Client2Image from "../assets/images/Client2.png";
import Client3Image from "../assets/images/Client3.png";
import Client4Image from "../assets/images/Client4.png";
import Client5Image from "../assets/images/Client5.png";
import Client6Image from "../assets/images/Client6.png";

const ExclusiveAdvertisers = () => {
  return (
    <div>
      <section id="exclusiveadvertisers" className="cases-section">
        <div className="auto-container">
          <div className="sec-title text-center"></div>
          <div className="sec-title">
            <h2>Our Advertisers</h2>
          </div>
          <div className="row clearfix">
            <div className="case-block col-lg-4 col-md-6 col-sm-12">
              <div className="image-box">
                <div className="image">
                  <a
                    href="http://summerrealtors.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Client1Image} alt="" />
                  </a>
                </div>
                <div className="caption-box">
                  <h5>
                    <a
                      href="http://summerrealtors.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Summer Realtors
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="case-block col-lg-4 col-md-6 col-sm-12">
              <div className="image-box">
                <div className="image">
                  <a
                    href="http://dannyhairstudio.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Client2Image} alt="" />
                  </a>
                </div>
                <div className="caption-box">
                  <h5>
                    <a
                      href="http://dannyhairstudio.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Danny Hair Studio
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="case-block col-lg-4 col-md-6 col-sm-12">
              <div className="image-box">
                <div className="image">
                  <a href="http://dcmil.com/" target="_blank" rel="noopener noreferrer">
                    <img src={Client3Image} alt="" />
                  </a>
                </div>
                <div className="caption-box">
                  <h5>
                    <a
                      href="http://dcmil.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      DC Metro Immigration Law
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="case-block col-lg-4 col-md-6 col-sm-12">
              <div className="image-box">
                <div className="image">
                  <a
                    href="http://makdachiro.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Client4Image} alt="" />
                  </a>
                </div>
                <div className="caption-box">
                  <h5>
                    <a
                      href="http://makdachiro.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Makda Chiropractor
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="case-block col-lg-4 col-md-6 col-sm-12">
              <div className="image-box">
                <div className="image">
                  <a
                    href="http://www.visitingnursesolution.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Client5Image} alt="" />
                  </a>
                </div>
                <div className="caption-box">
                  <h5>
                    <a
                      href="http://www.visitingnursesolution.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Visiting Nurse Solution
                    </a>
                  </h5>
                </div>
              </div>
            </div>
            <div className="case-block col-lg-4 col-md-6 col-sm-12">
              <div className="image-box">
                <div className="image">
                  <a
                    href="https://www.ethiopiatrustfund.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Client6Image} alt="" />
                  </a>
                </div>
                <div className="caption-box">
                  <h5>
                    <a
                      href="https://www.ethiopiatrustfund.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      The Ethiopia Diaspora Trust Fund
                    </a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-box">
            <Link to={{ pathname: "/advertisers-links", hash: "#" }} className="theme-btn btn-style-three ">
              View All
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ExclusiveAdvertisers;
