import React, { useState, useEffect } from "react";
import { getNextPublishDate } from "./dao/FirestoreDao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faClock,
  faEnvelope,
  faInbox,
} from "@fortawesome/free-solid-svg-icons";

const TopHeader = () => {
  const [nextPublishDate, setNextPublishDate] = useState("");

  useEffect(() => {
    getNextPublishDate()
      .then((date) => setNextPublishDate(date))
      .catch((error) =>
        console.log("Error setting next publish date: ", error)
      );
  }, []);

  return (
    <header id="header-id" className="main-header">
      
      {/* Header Top */}
      <div id="home" className="header-top">
        <div className="auto-container">
          <div className="inner-container clearfix">
            <div className="top-left">
              <ul className="contact-list clearfix">
                <li>
                  <FontAwesomeIcon icon={faClock} /> Next Edition -{" "}
                  {nextPublishDate}
                </li>
                <li>
                  <FontAwesomeIcon icon={faPhone} />{" "}
                  <a href="tel:+17037866100">(703)-786-6100</a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faInbox} />{" "}
                  <a href="mail:7866100@gmail.com">7866100@gmail.com</a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faEnvelope} /> P. O. Box 11797
                  Alexandria, VA 22312
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Header Top */}

    </header>
  );
};

export default TopHeader;
