import React from "react";
import TopHeader from "./TopHeader";
import LowerHeaderOthers from "./LowerHeaderOthers";
import AdvertisersLinksList from "./AdvertisersLinksList";
import Footer from "./Footer";

const AdvertisersLinks = () => {
  return (
    <div className="home-container">
      <TopHeader />
      <LowerHeaderOthers />
      <AdvertisersLinksList/>
      <Footer/>
    </div>
  );
};

export default AdvertisersLinks;
