import { db } from "../services/Firebase";
import { collection, query, getDocs, limit } from "firebase/firestore";

export function getNextPublishDate() {
  const q = query(
    collection(db, "publications"),
    limit(1)
  );
  return getDocs(q)
    .then((querySnapshot) => {
      let nextPublishDate = "";
      querySnapshot.docs.forEach((doc) => {
        nextPublishDate = doc.data().publication_date;
      });
      return nextPublishDate;
    })
    .catch((error) => {
      console.log("Error getting next publish date: ", error);
      return ""; // default value
    });
}
