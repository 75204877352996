import React from "react";
import advertisersList from '../data/advertisers.list.json';

const AdvertisersLinksList = () => {
  return (
    <div>
      <section className="cases-section">
        <div className="auto-container">
          <div className="filter-list row clearfix fail" id="MixItUpF66C0F">
            <div className="advertisers-list">
              {advertisersList.map((advertiser, index) => (
                <p key={index}>
                  <a
                    href={advertiser.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-mce-href={advertiser.url}
                  >
                    {advertiser.name}
                  </a>
                </p>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdvertisersLinksList;
