import React, { useState, useEffect }  from "react";
import { getNextPublishDate } from "./dao/FirestoreDao";
import AboutImg from "../assets/images/image-1.jpg";
import HeaderTxtImg from "../assets/images/header-text.jpeg";

const About = () => {
  const [nextPublishDate, setNextPublishDate] = useState("");

  useEffect(() => {
    getNextPublishDate()
      .then((date) => setNextPublishDate(date))
      .catch((error) =>
        console.log("Error setting next publish date: ", error)
      );
  }, []);

  return (
    <div>
      <section id="about" className="about-us">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <figure className="image">
                  <img src={HeaderTxtImg} alt="" />
                </figure>
                <div className="text">
                  Established in August 2002, Atref Advertisements, LLC is an
                  advertising paper serving the community in the Washington, DC
                  Metro area with advertising, information and entertainment.
                </div>
                <div className="text">
                  Atref is distributed in more than 100 places including
                  markets, restaurants and different businesses in Northern
                  Virginia, Washington, DC and Southern Maryland. The total
                  circulation is 8,000 copies.
                </div>
                <div id="next-edition-button" className="link-box">
                  <a href="##" className="theme-btn btn-style-three">
                    Next Edition: {nextPublishDate}
                  </a>
                  <a href="tel:+17037866100" className="theme-btn call-btn">
                    <i className="icon la la-phone"></i>(703)-786-6100
                  </a>
                </div>
                <div>
                  <a
                    href="mailto:7866100@gmail.com"
                    className="theme-btn call-btn"
                  >
                    <i className="icon la la-envelope"></i>7866100@gmail.com
                  </a>
                </div>
              </div>
            </div>
            <div className="video-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <figure className="image">
                  <img src={AboutImg} alt="" />
                </figure>
                <div className="link-box">
                  <div
                    className="link"
                    data-fancybox="gallery"
                    data-caption=""
                    rel="noreferrer"
                  >
                    <span className="icon la la-play-circle-o"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
