import React from "react";
import Service1Image from "../assets/images/service-1.jpg";
import Service2Image from "../assets/images/service-2.jpg";
import Service3Image from "../assets/images/service-3.jpg";

const Services = () => {
  return (
    <div>
      <section id="services" className="services-section sections-decor-1">
        <div className="auto-container">
          <div className="sec-title light">
            <span className="title">Our Services</span>
            <h2>What We Provide</h2>
          </div>
          <div className="services-carousel owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-stage-outer">
              <div className="owl-stage owl-staged-transform">
                <div className="owl-item cloned owl-cloned-style">
                  <div className="service-block">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure>
                          <img src={Service1Image} alt="" />
                        </figure>
                        <div className="overlay-box">
                          <div className="text">
                            We publish your advertisement in color or black and
                            white. Please refer to the rate card for prices.
                          </div>
                        </div>
                      </div>
                      <div className="caption-box">
                        <div className="caption">
                          <span className="icon flaticon-magnifying-glass"></span>
                          <h3>
                            <a href="##">Business Advertisements</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-item cloned owl-cloned-style">
                  <div className="service-block">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure>
                          <img src={Service2Image} alt="" />
                        </figure>
                        <div className="overlay-box">
                          <div className="text">
                            We publish advertisement if you are looking for a
                            baby sitter and baby sitting jobs.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="caption-box">
                        <div className="caption">
                          <span className="icon flaticon-boy-broad-smile"></span>
                          <h3>
                            <a href="##">Babysitter Ads and Others</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-item cloned owl-cloned-style">
                  <div className="service-block">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure>
                          <img src={Service3Image} alt="" />
                        </figure>
                        <div className="overlay-box">
                          <div className="text">
                            We publish advertisement for houses/apartments/rooms
                            for rent.
                          </div>
                        </div>
                      </div>
                      <div className="caption-box">
                        <div className="caption">
                          <span className="icon flaticon-home"></span>
                          <h3>
                            <a href="##">Room for Rent Ads</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-item owl-cloned-style">
                  <div className="service-block">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure>
                          <img src={Service1Image} alt="" />
                        </figure>
                        <div className="overlay-box">
                          <div className="text">
                            We publish your advertisement in color or black and
                            white. Please refer to the rate card for prices.
                          </div>
                        </div>
                      </div>
                      <div className="caption-box">
                        <div className="caption">
                          <span className="icon flaticon-magnifying-glass"></span>
                          <h3>
                            <a href="##">Business Advertisements</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-item active owl-cloned-style">
                  <div className="service-block">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure>
                          <img src={Service2Image} alt="" />
                        </figure>
                        <div className="overlay-box">
                          <div className="text">
                            We publish advertisement if you are looking for a
                            baby sitter and baby sitting jobs.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="caption-box">
                        <div className="caption">
                          <span className="icon flaticon-boy-broad-smile"></span>
                          <h3>
                            <a href="##">Babysitter Ads and Others</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-item active owl-cloned-style">
                  <div className="service-block">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure>
                          <img src={Service3Image} alt="" />
                        </figure>
                        <div className="overlay-box">
                          <div className="text">
                            We publish advertisement for houses/apartments/rooms
                            for rent.
                          </div>
                        </div>
                      </div>
                      <div className="caption-box">
                        <div className="caption">
                          <span className="icon flaticon-home"></span>
                          <h3>
                            <a href="##">Room for Rent Ads</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-item cloned active owl-cloned-style">
                  <div className="service-block">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure>
                          <img src={Service1Image} alt="" />
                        </figure>
                        <div className="overlay-box">
                          <div className="text">
                            We publish your advertisement in color or black and
                            white. Please refer to the rate card for prices.
                          </div>
                        </div>
                      </div>
                      <div className="caption-box">
                        <div className="caption">
                          <span className="icon flaticon-magnifying-glass"></span>
                          <h3>
                            <a href="##">Business Advertisements</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-item cloned owl-cloned-style">
                  <div className="service-block">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure>
                          <img src={Service2Image} alt="" />
                        </figure>
                        <div className="overlay-box">
                          <div className="text">
                            We publish advertisement if you are looking for a
                            baby sitter and baby sitting jobs.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="caption-box">
                        <div className="caption">
                          <span className="icon flaticon-boy-broad-smile"></span>
                          <h3>
                            <a href="##">Babysitter Ads and Others</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="owl-item cloned owl-cloned-style">
                  <div className="service-block">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure>
                          <img src={Service3Image} alt="" />
                        </figure>
                        <div className="overlay-box">
                          <div className="text">
                            We publish advertisement for houses/apartments/rooms
                            for rent.
                          </div>
                        </div>
                      </div>
                      <div className="caption-box">
                        <div className="caption">
                          <span className="icon flaticon-home"></span>
                          <h3>
                            <a href="##">Room for Rent Ads</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="owl-nav disabled">
              <div className="owl-prev">
                <span className="la la-angle-left"></span>
              </div>
              <div className="owl-next">
                <span className="la la-angle-right"></span>
              </div>
            </div>
            <div className="owl-dots disabled">
              <div className="owl-dot active">
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
