import React from 'react';
import About from './About';
import TopHeader from './TopHeader';
import LowerHeaderHome from './LowerHeaderHome';
import ServicesSection from './Services';
import FactSheetSection from './FactSheet';
import AdRateSection from './AdRate';
import AdSizesSection from './AdSizes';
import ExclusiveAdvertisersSection from './ExclusiveAdvertisers.js';
import ContactForm from './ContactForm';
import Footer from './Footer.js';

const Home = () => {
    return (
        <div className="home-container">
            <TopHeader/>
            <LowerHeaderHome/>
            <About/>
            <ServicesSection/>
            <FactSheetSection/>
            <AdRateSection/>
            <AdSizesSection/>
            <ExclusiveAdvertisersSection/>
            <ContactForm/>
            <Footer/>
        </div>
    )
}

export default Home;