import React from "react";
import TopHeader from "./TopHeader";
import LowerHeaderOthers from "./LowerHeaderOthers";
import ImportantLinksList from "./ImportantLinksList";
import Footer from "./Footer";

const ImportantLinks = () => {
  return (
    <div className="home-container">
      <TopHeader />
      <LowerHeaderOthers />
      <ImportantLinksList/>
      <Footer/>
    </div>
  );
};

export default ImportantLinks;
