import React from "react";
import AdSizeImages from "../assets/images/image-2.jpg";

const AdSizes = () => {
  return (
    <div>
      <section id="adsizes" className="why-us-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-column order-last col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  <h2>Ad Sizes</h2>
                </div>
                <ul className="list-style-two clearfix">
                  <li>Full page: 9.5" Wide X 12.5" Height</li>
                  <li>Half Page (Horizontal): 9.5" Wide X 6.25" Height</li>
                  <li>Half Page (Vertical): 4.75" Wide X 12.5" Height</li>
                  <li>Quarter Page: 4.75" Wide X 6.25" Height</li>
                  <li>1/8 Page: 4.75" Wide X 3" Height</li>
                  <li>1/16 Page: 4.75" Wide X 1.5" Height</li>
                </ul>
              </div>
            </div>
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="image">
                  <a
                    href="images/resource/image-2.jpg"
                    className="lightbox-image"
                    data-fancybox="images"
                  >
                    <img src={AdSizeImages} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdSizes;
