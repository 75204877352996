import React from "react";
import ManReadingImg from "../assets/images/001.jpg";

const FactSheet = () => {
  return (
    <div>
      <section id="factsheets" className="why-us-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-column order-last col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  <h2>Factsheet</h2>
                </div>
                <ul className="list-style-two clearfix">
                  <li>Publication Name: Atref Advertisements, LLC</li>
                  <li>Established: August 14, 2002</li>
                  <li>Current Circulation: 8,000</li>
                  <li>Print Dimensions: 10.44" x 13.75"</li>
                  <li>Print Frequency: Every 3 Weeks</li>
                  <li>Price: Free</li>
                  <li>Focus: Advertising, Information and Entertainment</li>
                  <li>Distribution Method: Bundle Dropped</li>
                  <li>
                    Distribution Sites: More than 100 business in Washington
                    D.C, Maryland and Virginia
                  </li>
                </ul>
              </div>
            </div>
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="image">
                  <a
                    href="images/resource/001.jpg"
                    className="lightbox-image"
                    data-fancybox="images"
                  >
                    <img src={ManReadingImg} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FactSheet;
