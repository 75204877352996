import React from "react";

const Footer = () => {
  const FOOTER_ENV_MARKER = process.env.REACT_APP_FOOTER_ENV_MARKER;
  return (
    <div>
      <footer className="main-footer">
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="inner-container clearfix">
              <div className="footer-links">
                <ul className="bottom-links">
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="##">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#services">Services</a>
                  </li>
                  <li>
                    <a href="##">Legals</a>
                  </li>
                  <li>
                    <a href="#contact">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="copyright-text">
                <p>
                  {" "}
                  © 2019 Atref Advertisements, LLC, All rights reserved.
                </p>
                <p>P. O. Box 11797, Alexandria, VA 22312</p>
                <p>7866100@gmail.com</p>
                <p>(703)-786-6100</p>
                <p>{FOOTER_ENV_MARKER}</p>
              </div>
              <div
                className="footer-deco scroll-to-top scroll-to-target"
                data-target="html"
              >
                <span className="la la-angle-up"></span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
