import React from "react";
import linksList from "../data/importantlinks.list.json";

const ImportantLinksList = () => {
  return (
    <div>
      <section className="cases-section">
        <div className="auto-container">
          <div className="filter-list row clearfix fail" id="MixItUpF66C0F">
            <div className="advertisers-list">
            {linksList.map((link, index) => (
                <p key={index}>
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-mce-href={link.url}
                  >
                    {link.name}
                  </a>
                </p>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ImportantLinksList;
